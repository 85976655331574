@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: 'Montserrat', sans-serif;
}

button, a {
  background-color: #84E095;
  border: none;
  color: black;
  padding: 15px 32px !important;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 4px 2px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;  
  cursor: pointer;
  border: 2px solid #2F8532;
  border-radius: 4px;
  width: 25vw;
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.4s;  
  min-width: -webkit-fit-content;  
  min-width: -moz-fit-content;  
  min-width: fit-content;
}
button:hover, a:hover {
  background-color: #2F8532;
  color: white;
}

#root {
  position: relative;
}

canvas {
  display: none;
}
img {
  max-width: 100vw;
  max-height: 100vh;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

