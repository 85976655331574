
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-family: 'Montserrat', sans-serif;
}

button, a {
  background-color: #84E095;
  border: none;
  color: black;
  padding: 15px 32px !important;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 4px 2px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;  
  cursor: pointer;
  border: 2px solid #2F8532;
  border-radius: 4px;
  width: 25vw;
  font-family: 'Montserrat', sans-serif;
  transition-duration: 0.4s;  
  min-width: fit-content;
}
button:hover, a:hover {
  background-color: #2F8532;
  color: white;
}

#root {
  position: relative;
}
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

canvas {
  display: none;
}
img {
  max-width: 100vw;
  max-height: 100vh;
}